@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap);
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: left;

  height: 100%;
  display: flex;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
	font-family: 'dinregularwebfont';
	src: url(/static/media/dinregularwebfont.f7a895de.eot);
	src: local('dinregularwebfont'),
       url(/static/media/dinregularwebfont.26df01e4.woff) format('woff'),
       url(/static/media/dinregularwebfont.3d000e76.ttf) format('truetype');
}

body {
    font-family: 'dinregularwebfont', sans-serif;
    overflow: hidden;
}

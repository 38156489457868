@font-face {
	font-family: 'dinregularwebfont';
	src: url('./fonts/dinregularwebfont/dinregularwebfont.eot');
	src: local('dinregularwebfont'),
       url('./fonts/dinregularwebfont/dinregularwebfont.woff') format('woff'),
       url('./fonts/dinregularwebfont/dinregularwebfont.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap');

body {
    font-family: 'dinregularwebfont', sans-serif;
    overflow: hidden;
}